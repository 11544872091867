import Icon from '@ant-design/icons';

export function Sun() {
    return (
        <Icon
            component={
                () => (
                    <svg focusable="false" xmlns="http://www.w3.org/2000/svg"
                        enableBackground="new 0 0 24 24" height="14" viewBox="0 0 24 24" width="14">
                        <rect fill="none" height="14" width="14"></rect>
                        <path fill="white" d="M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S14.76,7,12,7L12,7z M2,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0 c-0.55,0-1,0.45-1,1S1.45,13,2,13z M20,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S19.45,13,20,13z M11,2v2 c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1S11,1.45,11,2z M11,20v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2c0-0.55-0.45-1-1-1 C11.45,19,11,19.45,11,20z M5.99,4.58c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06 c0.39,0.39,1.03,0.39,1.41,0s0.39-1.03,0-1.41L5.99,4.58z M18.36,16.95c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41 l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0c0.39-0.39,0.39-1.03,0-1.41L18.36,16.95z M19.42,5.99c0.39-0.39,0.39-1.03,0-1.41 c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L19.42,5.99z M7.05,18.36 c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L7.05,18.36z">
                        </path>
                    </svg>
                )
            }
        />
    );
}

export function Moon() {
    return (
        <Icon
            component={
                () => (
                    <svg focusable="false" xmlns="http://www.w3.org/2000/svg"
                        enableBackground="new 0 0 24 24" height="14" viewBox="0 0 24 24" width="14">
                        <rect fill="none" height="14" width="14"></rect>
                        <path fill="white" d="M12,3c-4.97,0-9,4.03-9,9s4.03,9,9,9s9-4.03,9-9c0-0.46-0.04-0.92-0.1-1.36c-0.98,1.37-2.58,2.26-4.4,2.26 c-2.98,0-5.4-2.42-5.4-5.4c0-1.81,0.89-3.42,2.26-4.4C12.92,3.04,12.46,3,12,3L12,3z">
                        </path>
                    </svg>
                )
            }

        />

    );
}

export function Logo({ width = 200, height = 60 }) {
    return (
        <Icon
            component={
                () => (
                    <svg id="Group_652" data-name="Group 652" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 322.057 74" fontSize={50}>
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_19173" data-name="Rectangle 19173" width="322.057" height="74" fill="black"/>
    </clipPath>
  </defs>
  <g id="Group_651" data-name="Group 651" clipPath="url(#clip-path)">
    <path id="Path_2947" data-name="Path 2947" d="M112.647.751H40.139c-.7,4.236-.543,9.632.435,12.3A35.777,35.777,0,0,1,54.493,4.024,37.035,37.035,0,0,1,71.12,1.663c.094,1.521.246,2.926.254,4.338q.022,28.961.007,57.913a27.647,27.647,0,0,1-.183,4.038,4.075,4.075,0,0,1-1.549,2.866c-1.032.672-2.656.4-3.771.1a7.312,7.312,0,0,1-2.952-1.575,13.983,13.983,0,0,1-3.48-5.373C52.8,47.4,45.953,30.923,39.168,14.411,37.2,9.632,35.2,4.867,33.171,0H25.415c-.572,1.086-1.108,2.013-1.557,2.984-3.512,7.546-7.1,15.056-10.479,22.659-3.86,8.7-7.749,17.4-11.2,26.274A27.758,27.758,0,0,0,0,61.861c0,2.971.529,6.412,2.554,8.712.1.109.194.214.294.317,2.744,2.8,8.013,3.314,11.714,3.049a25.541,25.541,0,0,0,11.658-3.894c4.515-2.838,8.736-6.925,10.942-11.848C29.4,64.329,23.706,66.48,15.371,66.43c-7.93-.058-11.247-3.447-11.1-11.3.1-5.424,1.818-10.464,3.947-15.317,4.9-11.152,10.023-22.2,15.078-33.284.115-.253.485-.391.746-.594.118.2.227.4.336.607,2.256,4.222,4.03,8.746,5.9,13.143q4.435,10.4,8.664,20.9C41.587,47.1,44.2,53.636,46.7,60.211c.823,2.163,1.644,4.33,2.4,6.519.668,1.947,1.325,3.666-1.1,4.406l-3.563,1.082.012.854q5.308,0,10.616,0l10.726-.007,10.726-.007q5.089,0,10.177.012c.6,0,1.191.006,1.786.006.022-.261-.037-.569-.023-.837-6.561-.977-7.046-1.535-7.046-8.321Q81.395,34.954,81.413,6c.007-1.412.159-2.817.254-4.338A37.036,37.036,0,0,1,98.294,4.024a35.777,35.777,0,0,1,13.919,9.023c.978-2.665,1.13-8.06.435-12.3" transform="translate(0)" fill="#ff6a02"/>
    <path id="Path_2948" data-name="Path 2948" d="M251.341,52.879a1.642,1.642,0,0,0-.078.524,1.082,1.082,0,0,0,.311.66,1.17,1.17,0,0,0,.854.407l.854.078q.5.078.5.5,0,.544-.582.544l-3.378-.156-3.533.156c-.362,0-.544-.175-.544-.524s.143-.525.427-.525l.738-.078a2.557,2.557,0,0,0,2.252-1.591L259.146,28.3q.154-.466.912-.466t.951.466l9.94,24.578a2.344,2.344,0,0,0,2.213,1.591l.815.078a.339.339,0,0,1,.272.155.523.523,0,0,1,.116.311q0,.583-.543.583l-4.465-.156-4,.156c-.363,0-.544-.156-.544-.466q0-.543.466-.582l.893-.078a1.239,1.239,0,0,0,.835-.388,1,1,0,0,0,.33-.66,1.2,1.2,0,0,0-.117-.543l-2.291-5.941a.567.567,0,0,0-.621-.389H254.254a.6.6,0,0,0-.621.389Zm3.611-8.115h8.659q.427,0,.272-.466l-4.621-11.881-4.62,11.958c-.078.259.025.389.311.389" transform="translate(-106.686 -12.084)" fill="#ff6a02"/>
    <path id="Path_2949" data-name="Path 2949" d="M346.4,55.291a.5.5,0,0,1-.175.389.551.551,0,0,1-.369.155l-4.387-.155-3.844.155q-.544,0-.544-.485a.5.5,0,0,1,.427-.564l.66-.078q1.631-.194,1.631-1.592l-.311-18.365q0-.388-.233-.388a.152.152,0,0,0-.155.117L328.544,55.6a.555.555,0,0,1-.466.35q-.272,0-.427-.35l-10.6-20.5a.153.153,0,0,0-.155-.117c-.156,0-.234.13-.234.389l-.233,17.744q0,1.281,1.553,1.553l.66.117a.514.514,0,0,1,.5.544q0,.466-.582.466l-3.184-.117-3.766.117c-.363,0-.543-.156-.543-.466a.487.487,0,0,1,.426-.544l1.127-.117q1.785-.194,1.786-1.553l.466-22.054q.038-1.359-1.553-1.553l-.7-.078a.509.509,0,0,1-.466-.563q0-.486.583-.486l3.417.117q1.32.039,1.669.776l10.988,21.744,10.716-21.744q.351-.7,1.709-.776l3.455-.117q.621,0,.621.486a.508.508,0,0,1-.466.563l-.7.078a1.423,1.423,0,0,0-1.515,1.553l.466,22.054a1.368,1.368,0,0,0,.485,1.106,2.207,2.207,0,0,0,1.223.486l1.126.078q.466,0,.466.5" transform="translate(-135.044 -12.322)" fill="#ff6a02"/>
    <path id="Path_2950" data-name="Path 2950" d="M406.028,27.836a12.919,12.919,0,0,1,9.823,3.9,13.979,13.979,0,0,1,3.708,10.037,14.083,14.083,0,0,1-3.786,10.114,14.113,14.113,0,0,1-19.627.02,13.981,13.981,0,0,1-3.766-10.037,14.048,14.048,0,0,1,3.766-10.056,12.977,12.977,0,0,1,9.882-3.98m7.63,6.1a8.426,8.426,0,0,0-3.106-3.145,8.949,8.949,0,0,0-4.621-1.165,9.3,9.3,0,0,0-7.319,2.892,11.376,11.376,0,0,0-2.582,7.863,18.3,18.3,0,0,0,2.329,9.241,8.685,8.685,0,0,0,7.766,4.465,8.953,8.953,0,0,0,7.2-3.029A12.15,12.15,0,0,0,415.91,42.9a17.423,17.423,0,0,0-2.252-8.969" transform="translate(-170.342 -12.084)" fill="#ff6a02"/>
    <path id="Path_2951" data-name="Path 2951" d="M461.591,45.672V31.034q0-1.4-1.786-1.553l-1.126-.117a.465.465,0,0,1-.427-.505.48.48,0,0,1,.544-.543l4.387.156,3.844-.156q.583,0,.583.466a.506.506,0,0,1-.5.583l-.66.117q-1.553.272-1.553,1.553V45.71a7.3,7.3,0,0,0,2.174,5.63,9.092,9.092,0,0,0,11.474-.039,7.3,7.3,0,0,0,2.193-5.591V31.034q0-1.282-1.553-1.553l-.66-.117a.513.513,0,0,1-.505-.544q0-.466.583-.466l3.028.117,3.767-.117c.362,0,.543.156.543.466a.487.487,0,0,1-.427.544l-1.127.117q-1.786.194-1.786,1.553V45.672a10.031,10.031,0,0,1-2.932,7.591,11.364,11.364,0,0,1-15.084-.1,10,10,0,0,1-2.99-7.493" transform="translate(-198.939 -12.293)" fill="#ff6a02"/>
    <path id="Path_2952" data-name="Path 2952" d="M538.175,28.471a9.513,9.513,0,0,1,6.115,1.9,6.112,6.112,0,0,1,2.388,5.028,6.541,6.541,0,0,1-2.349,5.164,8.134,8.134,0,0,1-5.222,2.116l-.039.077a2.365,2.365,0,0,1,1.281,1.01l5.863,9.318a3.317,3.317,0,0,0,2.563,1.592l.7.078q.5.077.5.5c0,.363-.194.544-.583.544q-3.726-.155-4.64-.155a1.6,1.6,0,0,1-1.418-.776l-6.562-10.911a2.136,2.136,0,0,0-1.708-1.2h-2.834q-.582,0-.582.466v9.862a1.4,1.4,0,0,0,.5,1.126,2.025,2.025,0,0,0,1.243.466l1.631.078q.427,0,.427.524t-.543.524l-4.854-.155-4.31.155a.519.519,0,0,1-.582-.466q0-.544.466-.582l1.126-.078q1.592-.116,1.592-1.592V31.034q0-1.4-1.786-1.553l-1.126-.117a.465.465,0,0,1-.427-.505.48.48,0,0,1,.544-.543l5.358.155Zm3.164,11.046a4.863,4.863,0,0,0,1.805-3.941,4.776,4.776,0,0,0-1.786-3.9,6.9,6.9,0,0,0-4.5-1.456H533.2a1.373,1.373,0,0,0-1.553,1.553v8.736q0,.505.427.5h4.775a6.8,6.8,0,0,0,4.485-1.5" transform="translate(-227.921 -12.293)" fill="#ff6a02"/>
    <path id="Path_2953" data-name="Path 2953" d="M313.7,100.976v10.654a.658.658,0,0,0,.247.541,1.129,1.129,0,0,0,.608.238l.551.038c.152,0,.228.083.228.247a.242.242,0,0,1-.085.19.271.271,0,0,1-.181.076l-2.146-.076-2.355.076a.268.268,0,0,1-.18-.076.241.241,0,0,1-.086-.19c0-.164.076-.247.228-.247l.7-.038a1.129,1.129,0,0,0,.608-.238.659.659,0,0,0,.246-.541V100.976q0-.513-.474-.513h-1.6a2.044,2.044,0,0,0-1.462.5,3.372,3.372,0,0,0-.835,1.491q-.115.285-.313.285a.379.379,0,0,1-.294-.1.277.277,0,0,1-.076-.238l.152-2.45a.369.369,0,0,1,.418-.361h10.521q.4,0,.437.456l.19,2.355a.282.282,0,0,1-.076.247.4.4,0,0,1-.295.1q-.2,0-.313-.285a3.651,3.651,0,0,0-.865-1.5,2,2,0,0,0-1.433-.493h-1.6q-.475,0-.475.513" transform="translate(-133.289 -43.235)" fill="#ff6a02"/>
    <path id="Path_2954" data-name="Path 2954" d="M341.967,99.531a4.652,4.652,0,0,1,2.991.931,2.989,2.989,0,0,1,1.168,2.459,3.2,3.2,0,0,1-1.149,2.525,3.975,3.975,0,0,1-2.555,1.035l-.019.038a1.156,1.156,0,0,1,.627.494l2.868,4.558a1.622,1.622,0,0,0,1.253.779l.341.038c.165.025.247.107.247.246,0,.178-.1.266-.285.266q-1.824-.076-2.27-.076a.781.781,0,0,1-.693-.38l-3.21-5.337a1.046,1.046,0,0,0-.836-.589H339.06c-.19,0-.285.076-.285.227v4.824a.684.684,0,0,0,.247.551.99.99,0,0,0,.608.228l.8.038c.139,0,.209.085.209.256s-.089.256-.266.256L338,112.825l-2.108.076a.254.254,0,0,1-.285-.227c0-.178.076-.272.229-.285l.551-.038q.778-.057.779-.779V100.785q0-.684-.874-.76l-.551-.057a.227.227,0,0,1-.209-.247.235.235,0,0,1,.265-.267l2.621.076Zm1.547,5.4a2.377,2.377,0,0,0,.884-1.927,2.338,2.338,0,0,0-.874-1.909,3.377,3.377,0,0,0-2.2-.712h-1.785a.671.671,0,0,0-.759.76v4.273c0,.165.069.247.209.247h2.336a3.322,3.322,0,0,0,2.193-.732" transform="translate(-145.661 -43.176)" fill="#ff6a02"/>
    <path id="Path_2955" data-name="Path 2955" d="M374.025,112.825H366.9l-2.146.076q-.266,0-.266-.256c0-.171.07-.256.209-.256l.551-.038a1.154,1.154,0,0,0,.627-.238.659.659,0,0,0,.247-.541V100.785q0-.684-.874-.76l-.551-.057c-.139,0-.209-.085-.209-.256s.089-.256.266-.256l2.146.076h6.742a.816.816,0,0,1,.589.218.869.869,0,0,1,.267.542l.266,1.747q.037.342-.276.342a.393.393,0,0,1-.408-.3,2.306,2.306,0,0,0-.854-1.291,2.816,2.816,0,0,0-1.577-.38h-2.982a.95.95,0,0,0-.674.237.848.848,0,0,0-.256.655v3.988a.218.218,0,0,0,.247.247h3.532a.614.614,0,0,0,.418-.133.734.734,0,0,0,.2-.247q.048-.114.143-.418l.076-.247a.27.27,0,0,1,.285-.229c.151,0,.227.089.227.267l-.076,1.614.076,1.537a.26.26,0,0,1-.247.285.244.244,0,0,1-.266-.227l-.057-.342q-.171-.987-.779-.987h-3.551c-.152,0-.227.088-.227.266v4.406a.824.824,0,0,0,.256.637.953.953,0,0,0,.674.237H371.8a2.953,2.953,0,0,0,1.7-.484,2.548,2.548,0,0,0,1-1.415q.094-.3.313-.3a.375.375,0,0,1,.3.1.3.3,0,0,1,.067.237l-.3,1.975a.847.847,0,0,1-.276.542.826.826,0,0,1-.579.218" transform="translate(-158.235 -43.176)" fill="#ff6a02"/>
    <path id="Path_2956" data-name="Path 2956" d="M403.553,110.01V100.8q0-.665-.854-.76l-.551-.057a.238.238,0,0,1-.209-.265q0-.228.266-.228l1.842.057,1.481-.057q.285,0,.285.228a.25.25,0,0,1-.247.265l-.323.057q-.76.133-.759.76v12.022c0,.152-.07.227-.209.227h-.247a1,1,0,0,1-.817-.456l-8-10.236a.158.158,0,0,0-.114-.038c-.05,0-.076.063-.076.19v9.078q0,.626.759.759l.323.057a.251.251,0,0,1,.247.266q0,.228-.285.228l-1.481-.057-1.842.057q-.266,0-.265-.228a.238.238,0,0,1,.209-.266l.551-.057q.873-.094.874-.759V100.8q0-.665-.759-.76l-.342-.038a.248.248,0,0,1-.228-.276q0-.237.285-.237l1.519.057a1.077,1.077,0,0,1,.836.361l7.919,10.16a.182.182,0,0,0,.133.057c.05,0,.076-.037.076-.114" transform="translate(-170.386 -43.191)" fill="#ff6a02"/>
    <path id="Path_2957" data-name="Path 2957" d="M435.517,101.288a6.214,6.214,0,0,1,1.8,4.663,6.862,6.862,0,0,1-1.871,4.89,6.276,6.276,0,0,1-4.777,1.985h-3.267l-2.374.1c-.178,0-.266-.085-.266-.256s.07-.256.209-.256l.551-.038a1.152,1.152,0,0,0,.626-.237.66.66,0,0,0,.247-.542V100.785q0-.684-.874-.76l-.551-.057a.227.227,0,0,1-.209-.246.235.235,0,0,1,.266-.267l2.374.076h3.267a6.646,6.646,0,0,1,4.843,1.756m-1.1,1.092a4.121,4.121,0,0,0-1.539-1.444,4.544,4.544,0,0,0-2.222-.531h-1.709a.977.977,0,0,0-.674.227.842.842,0,0,0-.257.665v9.781a.825.825,0,0,0,.257.637.953.953,0,0,0,.674.237h1.8a4.409,4.409,0,0,0,3.494-1.5,5.7,5.7,0,0,0,1.311-3.893,7.6,7.6,0,0,0-1.14-4.178" transform="translate(-184.403 -43.176)" fill="#ff6a02"/>
    <path id="Path_2958" data-name="Path 2958" d="M465.965,109.287A3.1,3.1,0,0,1,464.626,112a5.544,5.544,0,0,1-3.246.93,9.114,9.114,0,0,1-3.134-.456q-1.226-.456-1.225-1.007v-2.222c0-.266.1-.4.3-.4.166,0,.279.12.342.36a5.049,5.049,0,0,0,1.282,2.175,3.182,3.182,0,0,0,2.212.712,3.809,3.809,0,0,0,2.308-.646,2.087,2.087,0,0,0,.883-1.785,2.467,2.467,0,0,0-1.405-2.108,15.641,15.641,0,0,0-1.386-.74q-.761-.361-1.519-.75a10.424,10.424,0,0,1-1.387-.845,3.094,3.094,0,0,1-1.405-2.592,2.967,2.967,0,0,1,1.206-2.488,4.541,4.541,0,0,1,2.839-.921,8.258,8.258,0,0,1,2.773.409q1.138.408,1.139.9v1.918c0,.254-.092.38-.276.38s-.321-.127-.409-.38a4.149,4.149,0,0,0-1.044-1.8,2.778,2.778,0,0,0-1.927-.589,3.094,3.094,0,0,0-2.022.608,1.877,1.877,0,0,0-.741,1.51,2.014,2.014,0,0,0,.741,1.557,7.66,7.66,0,0,0,1.794,1.158q1.053.5,2.108,1.045a5.924,5.924,0,0,1,1.794,1.4,2.9,2.9,0,0,1,.741,1.956" transform="translate(-198.404 -43.074)" fill="#ff6a02"/>
    <path id="Path_2959" data-name="Path 2959" d="M491.841,109.287A3.1,3.1,0,0,1,490.5,112a5.544,5.544,0,0,1-3.247.93,9.114,9.114,0,0,1-3.134-.456q-1.225-.456-1.225-1.007v-2.222q0-.4.3-.4c.165,0,.279.12.342.36a5.043,5.043,0,0,0,1.282,2.175,3.181,3.181,0,0,0,2.212.712,3.811,3.811,0,0,0,2.308-.646,2.087,2.087,0,0,0,.883-1.785,2.467,2.467,0,0,0-1.405-2.108,15.655,15.655,0,0,0-1.386-.74q-.761-.361-1.519-.75a10.421,10.421,0,0,1-1.386-.845,3.094,3.094,0,0,1-1.405-2.592,2.966,2.966,0,0,1,1.206-2.488,4.542,4.542,0,0,1,2.839-.921,8.256,8.256,0,0,1,2.773.409q1.138.408,1.139.9v1.918c0,.254-.092.38-.276.38s-.32-.127-.409-.38a4.149,4.149,0,0,0-1.044-1.8,2.779,2.779,0,0,0-1.928-.589,3.1,3.1,0,0,0-2.022.608,1.877,1.877,0,0,0-.741,1.51,2.014,2.014,0,0,0,.741,1.557,7.663,7.663,0,0,0,1.795,1.158q1.053.5,2.108,1.045a5.933,5.933,0,0,1,1.794,1.4,2.9,2.9,0,0,1,.741,1.956" transform="translate(-209.637 -43.074)" fill="#ff6a02"/>
    <line id="Line_198" data-name="Line 198" x2="31.51" transform="translate(137.037 63.158)" fill="#f77e0b"/>
    <line id="Line_199" data-name="Line 199" x2="31.51" transform="translate(289.843 63.158)" fill="#f77e0b"/>
  </g>
</svg>

                )
            }
        />

    );
}

export function LogoSmall({ width = 70, height = 50 }) {
    return (
        <Icon style={{marginTop:"5px"}}
            component={
                () => (
                    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 113.076 74" marginTop={10}>
  <g id="Group_659" data-name="Group 659" transform="translate(-1542 -225)">
    <path id="Path_2947" data-name="Path 2947" d="M112.647.751H40.139c-.7,4.236-.543,9.632.435,12.3A35.777,35.777,0,0,1,54.493,4.024,37.035,37.035,0,0,1,71.12,1.663c.094,1.521.246,2.926.254,4.338q.022,28.961.007,57.913a27.647,27.647,0,0,1-.183,4.038,4.075,4.075,0,0,1-1.549,2.866c-1.032.672-2.656.4-3.771.1a7.312,7.312,0,0,1-2.952-1.575,13.983,13.983,0,0,1-3.48-5.373C52.8,47.4,45.953,30.923,39.168,14.411,37.2,9.632,35.2,4.867,33.171,0H25.415c-.572,1.086-1.108,2.013-1.557,2.984-3.512,7.546-7.1,15.056-10.479,22.659-3.86,8.7-7.749,17.4-11.2,26.274A27.758,27.758,0,0,0,0,61.861c0,2.971.529,6.412,2.554,8.712.1.109.194.214.294.317,2.744,2.8,8.013,3.314,11.714,3.049a25.541,25.541,0,0,0,11.658-3.894c4.515-2.838,8.736-6.925,10.942-11.848C29.4,64.329,23.706,66.48,15.371,66.43c-7.93-.058-11.247-3.447-11.1-11.3.1-5.424,1.818-10.464,3.947-15.317,4.9-11.152,10.023-22.2,15.078-33.284.115-.253.485-.391.746-.594.118.2.227.4.336.607,2.256,4.222,4.03,8.746,5.9,13.143q4.435,10.4,8.664,20.9C41.587,47.1,44.2,53.636,46.7,60.211c.823,2.163,1.644,4.33,2.4,6.519.668,1.947,1.325,3.666-1.1,4.406l-3.563,1.082.012.854q5.308,0,10.616,0l10.726-.007,10.726-.007q5.089,0,10.177.012c.6,0,1.191.006,1.786.006.022-.261-.037-.569-.023-.837-6.561-.977-7.046-1.535-7.046-8.321Q81.395,34.954,81.413,6c.007-1.412.159-2.817.254-4.338A37.036,37.036,0,0,1,98.294,4.024a35.777,35.777,0,0,1,13.919,9.023c.978-2.665,1.13-8.06.435-12.3" transform="translate(1542 225)" fill="#ff6a02"/>
  </g>
</svg>
                )
            }
        />
    );
}

export function MenuFold() {
    return (
        <Icon
            component={
                () => (
                    <svg xmlns="http://www.w3.org/2000/svg" width="28.467" height="25.032" viewBox="0 0 28.467 25.032">
  <g id="Group_661" data-name="Group 661" transform="translate(-2231.31 -198.472)">
    <g id="Group_660" data-name="Group 660" transform="translate(-194.102 -183.834)">
      <rect id="Rectangle_75" data-name="Rectangle 75" width="5.607" height="28.037" rx="2.804" transform="translate(2453.449 391.997) rotate(90)" fill="#04362D"/>
      <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(2430.846 391.306)" fill="#E0BF8D" stroke="black" strokeWidth="1">
        <circle cx="3.516" cy="3.516" r="3.516" stroke="none"/>
        <circle cx="3.516" cy="3.516" r="3.016" fill="none"/>
      </g>
      <rect id="Rectangle_19176" data-name="Rectangle 19176" width="5.607" height="21.866" rx="2.804" transform="translate(2447.277 382.997) rotate(90)" fill="#04362D"/>
      <g id="Ellipse_99" data-name="Ellipse 99" transform="translate(2441.846 382.306)" fill="#E0BF8D" stroke="black" strokeWidth="1">
        <circle cx="3.516" cy="3.516" r="3.516" stroke="none"/>
        <circle cx="3.516" cy="3.516" r="3.016" fill="none"/>
      </g>
      <rect id="Rectangle_19177" data-name="Rectangle 19177" width="5.607" height="28.037" rx="2.804" transform="translate(2453.449 400.997) rotate(90)" fill="#04362D"/>
      <g id="Ellipse_100" data-name="Ellipse 100" transform="translate(2446.846 400.306)" fill="#E0BF8D" stroke="black" strokeWidth="1">
        <circle cx="3.516" cy="3.516" r="3.516" stroke="none"/>
        <circle cx="3.516" cy="3.516" r="3.016" fill="none"/>
      </g>
    </g>
  </g>
</svg>

                )
            }
        />
    );
}

export function TireIcon({ height = 45, width = 45 }) {
    return (
        <Icon
            spin={true}
            component={
                () => (
                    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 44.634 44.744">
                        <g id="tire" transform="translate(0 -0.002)">
                            <path id="Path_141" data-name="Path 141" d="M42.493,12.679a.868.868,0,0,0,.177,1.13,3.981,3.981,0,0,1,1.044,3.273,1.066,1.066,0,0,0,.156.754,3.741,3.741,0,0,1,.408,3.609c-.2.466.326.685.318,1.026-.026,1.018.245,2.068-.368,3.036-.462.73.1,1.551-.2,2.4a18.659,18.659,0,0,1-2.017,4.875A34.523,34.523,0,0,1,39.93,36.1a23.355,23.355,0,0,1-3.23,3.3c-1.286.93-2.409,2.2-3.941,2.692-1.443.467-2.682,1.576-4.2,1.608-1.408.029-2.572.937-3.962.829-1.575-.122-3.21.518-4.69.032-1.429-.469-3.024-.139-4.294-.927-1.211-.751-2.763-.691-3.808-1.626-.975-.874-2.257-1.2-3.2-2.063A23.09,23.09,0,0,1,5.3,36.72c-.951-1.318-2.243-2.47-2.756-4.034-.461-1.4-1.54-2.62-1.555-4.1-.013-1.3-.833-2.35-.792-3.62.019-.581-.368-1.184.194-1.728.135-.13.042-.42-.043-.653a3.813,3.813,0,0,1,.17-3.517c.288-.456-.2-.847-.089-1.153.376-1.046.35-2.181,1.115-3.177.5-.645.365-1.793.9-2.581.292-.429.273-1.151,1.066-1.167.111,0,.258-.359.314-.571a4.26,4.26,0,0,1,2.244-2.9,1.092,1.092,0,0,0,.574-.536A3.848,3.848,0,0,1,9.253,4.622a1.228,1.228,0,0,0,.573-.507,3.934,3.934,0,0,1,3.129-1.766c.216,0,.535-.062.627-.208A2.949,2.949,0,0,1,16.165.968C17.5.934,18.585.071,19.9.153c.5.032,1.034-.315,1.5.217.135.153.474,0,.743-.075a4.017,4.017,0,0,1,3.434.181c.159.1.419.229.538.166a2.974,2.974,0,0,1,2.922.425c1.077.607,2.328.621,3.336,1.3.492.33,1.215.365,1.343,1.165.027.168.4.358.635.4,1.306.249,1.98,1.316,2.928,2.059A20.76,20.76,0,0,1,40.772,9.86a19.857,19.857,0,0,1,1.721,2.819" transform="translate(0 0.001)" fill="#003d78" />
                            <path id="Path_142" data-name="Path 142" d="M44.514,28.933A15.576,15.576,0,1,1,28.967,44.564,15.5,15.5,0,0,1,44.514,28.933" transform="translate(-22.203 -22.176)" fill="#fdfdfd" />
                            <path id="Path_143" data-name="Path 143" d="M33.188,47.75A14.59,14.59,0,1,1,47.814,62.333,14.625,14.625,0,0,1,33.188,47.75" transform="translate(-25.438 -25.41)" fill="#f9423a" />
                            <path id="Path_144" data-name="Path 144" d="M122.768,85.738A14.076,14.076,0,0,1,118.6,95.779c-.481.489-.731.593-1.186-.067-1.2-1.747-2.515-3.419-3.75-5.145a3.556,3.556,0,0,1,1.624-5.464c2.132-.777,4.324-1.389,6.477-2.111.468-.157.6-.023.715.4a11.372,11.372,0,0,1,.292,2.349" transform="translate(-86.584 -63.562)" fill="#fdfdfd" />
                            <path id="Path_145" data-name="Path 145" d="M104.317,47.636a3.447,3.447,0,0,1-3.58-3.369c-.09-2.24-.007-4.485-.04-6.728-.009-.605.3-.613.719-.542a14.02,14.02,0,0,1,10.659,7.851c.121.237.321.526-.1.638-2.564.687-5,1.882-7.659,2.149" transform="translate(-77.18 -28.333)" fill="#fdfdfd" />
                            <path id="Path_146" data-name="Path 146" d="M52.876,40.748c0,1,.024,2.01-.005,3.014a3.621,3.621,0,0,1-4.629,3.483c-2.144-.614-4.24-1.4-6.374-2.051-.54-.166-.575-.348-.338-.79a14.079,14.079,0,0,1,10.531-7.668c.7-.118.856.118.828.767-.046,1.08-.012,2.163-.013,3.245" transform="translate(-31.725 -28.134)" fill="#fdfdfd" />
                            <path id="Path_147" data-name="Path 147" d="M36.37,86.119c-.006-3.195-.005-3.2,2.965-2.25,1.431.456,2.87.886,4.285,1.389A3.561,3.561,0,0,1,45.4,90.733c-1.238,1.771-2.569,3.476-3.806,5.247-.431.618-.689.468-1.118.031a13.906,13.906,0,0,1-4.1-9.891" transform="translate(-27.876 -63.862)" fill="#fdfdfd" />
                            <path id="Path_148" data-name="Path 148" d="M72.9,124.3a15.108,15.108,0,0,1-6.353-1.509c-.652-.315-.771-.555-.312-1.166,1.275-1.693,2.468-3.447,3.733-5.147a3.562,3.562,0,0,1,5.7-.159c1.418,1.775,2.666,3.683,4.025,5.506.421.565.054.714-.337.924A13.941,13.941,0,0,1,72.9,124.3" transform="translate(-50.557 -88.123)" fill="#fdfdfd" />
                            <path id="Path_149" data-name="Path 149" d="M87.824,90.179a2.388,2.388,0,1,1,.05-4.775,2.376,2.376,0,0,1,2.337,2.365,2.324,2.324,0,0,1-2.388,2.41" transform="translate(-65.486 -65.459)" fill="#fefefe" />
                            <path id="Path_150" data-name="Path 150" d="M93.642,40.867c0-1.276-.009-2.552.007-3.828,0-.3-.168-.725.421-.756.568-.03.5.34.5.683q0,3.886,0,7.772c0,.338.075.773-.428.794-.6.025-.494-.469-.5-.837-.008-1.276,0-2.552,0-3.828" transform="translate(-71.765 -27.808)" fill="#fdfdfd" />
                            <path id="Path_151" data-name="Path 151" d="M65.546,111.887c-.069.112-.176.313-.308.5q-2.248,3.092-4.507,6.176c-.178.242-.273.7-.776.347-.468-.324-.2-.569.005-.85q2.285-3.136,4.569-6.274c.185-.254.359-.529.733-.44.209.05.3.219.285.545" transform="translate(-45.755 -85.327)" fill="#fafafa" />
                            <path id="Path_152" data-name="Path 152" d="M112.4,118.575a3.371,3.371,0,0,1-.394.323c-.369.227-.471-.135-.611-.325-1.009-1.367-2-2.743-3-4.118-.567-.781-1.124-1.569-1.7-2.346-.177-.239-.288-.481-.065-.723a.428.428,0,0,1,.7.062q2.508,3.4,4.984,6.828a1.158,1.158,0,0,1,.083.3" transform="translate(-81.635 -85.254)" fill="#fafafa" />
                            <path id="Path_153" data-name="Path 153" d="M47.856,78.737c-.108.436-.356.485-.711.371q-3.89-1.259-7.785-2.506c-.3-.1-.45-.282-.332-.609s.379-.333.659-.242c2.557.829,5.111,1.664,7.67,2.486.287.092.482.216.5.5" transform="translate(-29.884 -58.019)" fill="#f7f7f7" />
                            <path id="Path_154" data-name="Path 154" d="M123.566,76.426c.092.548-.291.5-.508.57q-3.675,1.214-7.361,2.395c-.324.1-.751.254-.9-.2s.3-.553.619-.656c2.456-.793,4.916-1.573,7.371-2.367.4-.13.786-.308.775.255" transform="translate(-87.971 -58.281)" fill="#f8f8f8" />
                            <path id="Path_155" data-name="Path 155" d="M134.639,88.624a13.112,13.112,0,0,1-3.419,8.852c-.48.551-.8.626-1.159-.061a2.146,2.146,0,0,0-.41-.558c-.624-.592-.5-1.055.035-1.7a9.816,9.816,0,0,0,2.269-6.984c-.042-.814.134-1.141.915-1.364,1.726-.492,1.714-.536,1.768,1.238.006.193,0,.386,0,.579" transform="translate(-99.053 -66.31)" fill="#003d78" />
                            <path id="Path_156" data-name="Path 156" d="M103.409,41.38c0-1.548,0-1.55,1.376-1.193a13.28,13.28,0,0,1,8.271,6.224c.361.594.267.859-.391.989a2.6,2.6,0,0,0-.657.214c-.84.423-1.306.176-1.822-.64a9.21,9.21,0,0,0-5.561-4.07c-1.034-.271-1.459-.671-1.216-1.525" transform="translate(-79.21 -30.653)" fill="#003d78" />
                            <path id="Path_157" data-name="Path 157" d="M55.019,41.246c.315,1.092-.083,1.475-1.007,1.709a9.94,9.94,0,0,0-5.919,4.368c-.168.252-.2.76-.692.616a15.817,15.817,0,0,1-2.16-.771c-.211-.1.072-.416.174-.595a13,13,0,0,1,8.965-6.556c1.426-.3.324,1,.639,1.229" transform="translate(-34.618 -30.637)" fill="#003d78" />
                            <path id="Path_158" data-name="Path 158" d="M39.411,86.015a.789.789,0,0,1,.185-.021c2.26.636,2.243.637,2.371,2.951a10.141,10.141,0,0,0,2.347,5.922c.2.243.671.436.4.847a14.467,14.467,0,0,1-1.4,1.824c-.149.163-.372-.185-.514-.339a12.912,12.912,0,0,1-3.391-11.184" transform="translate(-30.073 -65.909)" fill="#003d78" />
                            <path id="Path_159" data-name="Path 159" d="M75.817,140.747a12.911,12.911,0,0,1-5.6-1.213c-.493-.214-.691-.439-.268-.9a2.312,2.312,0,0,0,.4-.564c.434-.934.978-1.041,1.985-.666a9.531,9.531,0,0,0,6.805.018c1.107-.419,1.566-.134,2.187.741s.556,1.22-.436,1.581a13.6,13.6,0,0,1-5.071,1" transform="translate(-53.435 -105.16)" fill="#003d78" />
                            <rect id="Rectangle_24" data-name="Rectangle 24" width="44.633" height="44.745" transform="translate(0 0.001)" fill="none" />
                        </g>
                    </svg>
                )
            }
        />
    );
}